import './PopUp.css';

function PopUp({  }) {
  return (
    <div className="PopUp"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "500px",
        height: "500px",
        position: "relative",
       }}
    >
      <img src='/images/sold_out.png' alt='alert' />
      <div id='pop_close' onClick={() => window.close()}>
        창 닫기
      </div>
    </div>
  );
}

export default PopUp;