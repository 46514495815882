const locationChange = (loca) => {
  window.scrollTo(0,0);
  window.location = loca;
}

const jaritsu = (num) => {
  let str = `${num}`;
  let result = '';

  for (let i = 1; i < str.length + 1; i++) {
    result = str[str.length - i] + result;

    if(i%3 === 0 && i < str.length) {
      result = ',' + result;
    }
  }

  return result;
}

export {
  locationChange,
  jaritsu,
}