import { locationChange, jaritsu } from "../../common/modules/js/commonFunc";
import { bag_arr, color_obj } from "../../content/content";

function MainRightPayment({ info }) {
  return (
    <div className="MainRightPayment" id="mr_payment">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          paddingLeft: "20px",
        }}
      >
        <div className="mp_form" style={{ width: "175px" }}>
          <div className="mp_name">받는 분</div>
          <div className="mp_input">
            <input type="text" spellCheck="false" autoComplete="off" />
          </div>
        </div>

        <div className="mp_form" style={{ width: "225px" }}>
          <div className="mp_name">전화번호</div>
          <div className="mp_input">
            <input type="text" spellCheck="false" autoComplete="off" />
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          paddingLeft: "20px",
        }}
      >
        <div className="mp_form" style={{ width: "450px" }}>
          <div className="mp_name">주소</div>
          <div className="mp_input">
            <input type="text" spellCheck="false" autoComplete="off" />
          </div>
        </div>
      </div>

      <div style={{ paddingLeft: "20px" }}>주문내역</div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          boxSizing: "border-box",
          padding: "20px",
        }}
      >
        <div id="mr_order_l">
          <div id="mr_order_image">
            <img src={`/images/${bag_arr[info[0]].img}.png`} alt="oi" />
          </div>
        </div>

        <div id="mr_order_r" style={{ borderRight: "none" }}>
          <div className="mr_order_row">
            <div id="mr_order_r_name">
              {bag_arr[info[0]].title}
              {bag_arr[info[0]].status.map((e, i) => {
                return (
                  <img
                    className="bags_status"
                    src={`/images/status/${e}.png`}
                    alt="s"
                    key={`bags_row_status2_key_${i}`}
                  />
                );
              })}
            </div>
          </div>

          <div className="mr_order_row">
            <div className="mor_l">
              <span className="mor_love">
                <img src="/images/love.jpg" alt="l" />
              </span>
              판매가
            </div>
            <div className="mor_colon">:</div>
            <div id="mr_order_r_price">
              {jaritsu(bag_arr[info[0]].price) + "원"}
            </div>
          </div>

          <div className="mr_order_row">
            <div className="mor_l">
              <span className="mor_love">
                <img src="/images/love.jpg" alt="l" />
              </span>
              색상
            </div>
            <div className="mor_colon">:</div>

            <div>{color_obj[info[1]]}</div>
          </div>

          <div className="mr_order_row">
            <div className="mor_l">
              <span className="mor_love">
                <img src="/images/love.jpg" alt="l" />
              </span>
              수량
            </div>
            <div className="mor_colon">:</div>

            <div>{info[2] + "개"}</div>
          </div>

          <div className="mr_order_row">
            <div className="mor_l">
              <span className="mor_love">
                <img src="/images/love.jpg" alt="l" />
              </span>
              합계
            </div>
            <div className="mor_colon">:</div>

            <div style={{ fontWeight: 600 }}>
              {jaritsu(bag_arr[info[0]].price * info[2]) + "원"}
            </div>
          </div>
        </div>
      </div>

      <div id="mr_payment_ok_box">
        <div
          className="login_btn mr_payment_ok"
          onClick={() => locationChange(`/`)}
        >
          주문취소
        </div>

        <div
          className="login_btn mr_payment_ok"
          onClick={() => locationChange(`/`)}
        >
          주문확인
        </div>
      </div>
    </div>
  );
}

export default MainRightPayment;
