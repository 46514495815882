import { locationChange, jaritsu } from "../../common/modules/js/commonFunc";

import { bag_arr, order_list } from "../../content/content";

function MainRightOrderList({ orderListPage }) {
  const orderListPageChange = (e) => {
    locationChange("/admin/orderlist/" + e.target.textContent);
  }
  
  return (
    <div className="MainRightOrderList">
      <div className="order_list">
        <div className="ol_row ol_header">
          <div className="ol_row_1">주문번호</div>
          <div className="ol_row_2">주문일자</div>
          <div className="ol_row_3">제품명</div>
          <div className="ol_row_4">결제방법</div>
          <div className="ol_row_5">결제금액</div>
          <div className="ol_row_6 end">배송상태</div>
        </div>

        {order_list[(orderListPage - 1) % 3].map((e, i) => {
          let cur_bag = bag_arr[20021450];

          if (e.stuff > 0) {
            cur_bag = bag_arr[(e.stuff % 7) + 20021450];
          }

          const date = String(e.date);

          return (
            <div className="ol_row" key={`ol_row_key_${i}`}>
              <div className="ol_row_1 ol_able">
                {40040830876 - i - 20 * (orderListPage - 1)}
              </div>
              <div className="ol_row_2">
                {date.slice(0, 4) +
                  "." +
                  date.slice(4, 6) +
                  "." +
                  date.slice(6)}
              </div>
              <div className="ol_row_3">{cur_bag.title || "undefined"}</div>
              <div className="ol_row_4">신용카드</div>
              <div className="ol_row_5">
                {jaritsu(cur_bag.price * e.amount) + "원"}
              </div>
              <div className="ol_row_6 end">주문확인중</div>
            </div>
          );
        })}
      </div>

      <div id="num_list">
        <div style={{ fontSize: "12px", marginRight: "5px", width: "80px" }}>
          전체 : 103
        </div>
        {/* <div className='btn'>◁</div> */}
        <div className="num_btn num_now">
          [<span onClick={orderListPageChange}>1</span>]
        </div>
        <div className="num_btn">
          [<span onClick={orderListPageChange}>2</span>]
        </div>
        <div className="num_btn">
          [<span onClick={orderListPageChange}>3</span>]
        </div>
        <div className="num_btn">
          [<span onClick={orderListPageChange}>4</span>]
        </div>
        <div className="num_btn">
          [<span onClick={orderListPageChange}>5</span>]
        </div>
        <div className="num_btn">
          [<span onClick={orderListPageChange}>6</span>]
        </div>
        <div className="num_btn">
          [<span onClick={orderListPageChange}>7</span>]
        </div>
        <div className="num_btn">
          [<span onClick={orderListPageChange}>8</span>]
        </div>
        <div className="num_btn">
          [<span onClick={orderListPageChange}>9</span>]
        </div>
        <div className="num_btn">
          [<span onClick={orderListPageChange}>10</span>]
        </div>
        <div className="num_btn" style={{ width: "80px" }}>
          ▶
        </div>
      </div>
    </div>
  );
}

export default MainRightOrderList;
