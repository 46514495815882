const bag_arr = {
  20021456: {
    title: `레더 백`,
    img: `white`,
    price: 32000,
    status: [5, 4],
  },
  20021455: {
    title: `트윙클 클러치백`,
    img: `white`,
    price: 42000,
    status: [5, 4],
  },
  20021454: {
    title: `큐티 미니백`,
    img: `white`,
    price: 27000,
    status: [5, 4],
  },
  20021453: {
    title: `모카 사각 숄더백`,
    img: `white`,
    price: 33000,
    status: [5, 4],
  },
  20021452: {
    title: `빅 복조리백`,
    img: `white`,
    price: 30000,
    status: [5, 4],
  },
  20021451: {
    title: `캐스터너츠 크로스백`,
    img: `white`,
    price: 25000,
    status: [5, 4],
  },
  20021450: {
    title: `벚꽃 사각 미니백`,
    img: `white`,
    price: 29000,
    status: [5, 4],
  },
  // 20021449: {
  //   title: `temp_08`,
  //   img: `white`,
  //   price: 50000,
  //   status: [5,4]
  // },
  // 20021448: {
  //   title: `temp_09`,
  //   img: `white`,
  //   price: 50000,
  //   status: [5,4]
  // },
};

const color_obj = {
  0: "블랙",
  1: "브라운",
  2: "핑크",
  3: "화이트",
};

const order_list = [
  [
    {
      date: 20020415,
      stuff: 0,
      amount: 7,
    },
    {
      date: 20020415,
      stuff: 1,
      amount: 8,
    },
    {
      date: 20020415,
      stuff: 2,
      amount: 9,
    },
    {
      date: 20020415,
      stuff: 3,
      amount: 5,
    },
    {
      date: 20020415,
      stuff: 1,
      amount: 1,
    },
    {
      date: 20020415,
      stuff: 6,
      amount: 5,
    },
    {
      date: 20020415,
      stuff: 5,
      amount: 3,
    },
    {
      date: 20020415,
      stuff: 2,
      amount: 5,
    },
    {
      date: 20020415,
      stuff: 3,
      amount: 10,
    },
    {
      date: 20020415,
      stuff: 5,
      amount: 4,
    },
    {
      date: 20020415,
      stuff: 4,
      amount: 8,
    },
    {
      date: 20020415,
      stuff: 2,
      amount: 6,
    },
    {
      date: 20020415,
      stuff: 0,
      amount: 8,
    },
    {
      date: 20020415,
      stuff: 1,
      amount: 1,
    },
    {
      date: 20020415,
      stuff: 0,
      amount: 9,
    },
    {
      date: 20020415,
      stuff: 5,
      amount: 1,
    },
    {
      date: 20020415,
      stuff: 2,
      amount: 10,
    },
    {
      date: 20020415,
      stuff: 6,
      amount: 9,
    },
    {
      date: 20020415,
      stuff: 3,
      amount: 10,
    },
    {
      date: 20020415,
      stuff: 1,
      amount: 10,
    },
  ],
  [
    {
      date: 20020414,
      stuff: 124,
      amount: 7,
    },
    {
      date: 20020414,
      stuff: 321,
      amount: 8,
    },
    {
      date: 20020414,
      stuff: 522,
      amount: 5,
    },
    {
      date: 20020414,
      stuff: 33,
      amount: 5,
    },
    {
      date: 20020414,
      stuff: 18,
      amount: 1,
    },
    {
      date: 20020414,
      stuff: 67,
      amount: 5,
    },
    {
      date: 20020414,
      stuff: 65,
      amount: 3,
    },
    {
      date: 20020414,
      stuff: 27,
      amount: 5,
    },
    {
      date: 20020414,
      stuff: 83,
      amount: 3,
    },
    {
      date: 20020414,
      stuff: 95,
      amount: 4,
    },
    {
      date: 20020413,
      stuff: 40,
      amount: 1,
    },
    {
      date: 20020413,
      stuff: 22,
      amount: 3,
    },
    {
      date: 20020413,
      stuff: 30,
      amount: 2,
    },
    {
      date: 20020413,
      stuff: 15,
      amount: 1,
    },
    {
      date: 20020413,
      stuff: 60,
      amount: 9,
    },
    {
      date: 20020413,
      stuff: 75,
      amount: 9,
    },
    {
      date: 20020413,
      stuff: 212,
      amount: 8,
    },
    {
      date: 20020413,
      stuff: 26,
      amount: 9,
    },
    {
      date: 20020413,
      stuff: 33,
      amount: 7,
    },
    {
      date: 20020413,
      stuff: 41,
      amount: 3,
    },
  ],
  [
    {
      date: 20020413,
      stuff: 42,
      amount: 2,
    },
    {
      date: 20020413,
      stuff: 41,
      amount: 3,
    },
    {
      date: 20020413,
      stuff: 23,
      amount: 1,
    },
    {
      date: 20020413,
      stuff: 35,
      amount: 4,
    },
    {
      date: 20020413,
      stuff: 16,
      amount: 1,
    },
    {
      date: 20020412,
      stuff: 63,
      amount: 5,
    },
    {
      date: 20020412,
      stuff: 55,
      amount: 3,
    },
    {
      date: 20020412,
      stuff: 24,
      amount: 3,
    },
    {
      date: 20020412,
      stuff: 31,
      amount: 2,
    },
    {
      date: 20020412,
      stuff: 52,
      amount: 1,
    },
    {
      date: 20020412,
      stuff: 43,
      amount: 1,
    },
    {
      date: 20020412,
      stuff: 22,
      amount: 2,
    },
    {
      date: 20020412,
      stuff: 35,
      amount: 1,
    },
    {
      date: 20020412,
      stuff: 58,
      amount: 3,
    },
    {
      date: 20020412,
      stuff: 20,
      amount: 2,
    },
    {
      date: 20020411,
      stuff: 5,
      amount: 3,
    },
    {
      date: 20020411,
      stuff: 23,
      amount: 4,
    },
    {
      date: 20020411,
      stuff: 5,
      amount: 1,
    },
    {
      date: 20020411,
      stuff: 26,
      amount: 1,
    },
    {
      date: 20020411,
      stuff: 98,
      amount: 3,
    },
  ],
];

const sample_image_list = [
  `/images/white.png`,
  `/images/white.png`,
  `/images/white.png`,
  `/images/white.png`,
  `/images/white.png`,
];

const review_list = [
  {
    name: `아톰믹§`,
    id: `winner0209`,
    text: `
    <p>사진 보고 괜찮을까 싶어서 샀는데, 잘 안 들게 되네요.</p>
    <p>패턴이나 디자인이 너무 복합적으로 들어가 있어서 조잡해요.</p>
    <p>깔끔하고 이쁘단 느낌이 전혀 안 듭니다.</p>
    `,
  },
  {
    name: `별헤는밤`,
    id: `gisik999vv`,
    text: `
    <p>수납력도 떨어지고, 데일리로 매기엔 디자인도 너무 화려해요. </p>
    <p>회사 출근하면서 매려고 했는데 도저히 안 될 것 같네요. </p>
    `,
  },
  {
    name: `^0^`,
    id: `hiiing`,
    text: `
    <p>가방 너무 허접한 듯...장식 치렁치렁 달려서 디자인 개오바.</p>
    <p>사진은 사기임. 실제로 받고 깜짝 놀랐음. 절대 사지 마셈.</p>
    `,
  },
  {
    name: `얼굴없는소녀`,
    id: `0suk0912`,
    text: `
    <p>중요한 모임이 있어서 급하게 시켰는데, 완전 대실패네요.</p>
    <p>수납공간이 적은데 무겁기는 또 왜이렇게 무거운지....</p>
    <p>반품하고 싶은데 귀찮아서 그냥 참습니다.</p>
    `,
  },
  {
    name: `psk2047`,
    id: `psk2047`,
    text: `
    <p>가방이 지나치게 올드한 느낌임. 이 정도 돈 쓸 거였으면, </p>
    <p>차라리 짝퉁을 사는 게 낫겠음. </p>
    `,
  },
  {
    name: `ĿU℃ⅠÞER`,
    id: `shisback`,
    text: `
    <p>가방 메고 나갔는데 촌스럽단 소리 열 번 넘게 들음. </p>
    <p>도저히 못 메고 집에 처박아둠.....OTL </p>
    `,
  },
  {
    name: `오빠야~`,
    id: `mda880311`,
    text: `
    <p>가격이 그렇게 싼 것도 아닌데 디자인이 이게 뭐임? -_-....</p>
    <p>차라리 그냥 아무것도 안 달고 평범하게 만들었으면 중간은 갔을 듯.</p>
    `,
  },
  {
    name: `별이。`,
    id: `hanstar222`,
    text: `
    <p>저도 사용후기 올려요~^^ </p>
    <p>가성비 떨어지고 메고 다니기 부담스러가지고 다신 여기서 안 사려구요~</p>
    `,
  },
  {
    name: `수지S2`,
    id: `sujilove0509`,
    text: `
    <p>화려한 걸 좋아해서 설레는 마음을 안고 가방을 주문하였습니당! </p>
    <p>전 아직 고등학생인데요.. 제가 쓰긴 좀..ㅎㅎ.. 저희 엄마도 그냥 버리래요..ㅠㅠ</p>
    `,
  },
  {
    name: `∑박공주™`,
    id: `1985parkuu`,
    text: `
    <p>사진은 예쁜데 메니까 넘 촌스러움... 환불 고민 중.. </p>
    `,
  },
  {
    name: `ⓔ뿐ⓖⓘⓡⓛ`,
    id: `qkrtnqls12`,
    text: `
    <p>뭐가 많이 달려서 관리하기 힘들 듯. 유행도 빨리 바뀌는데 이번에만 쓰고 장롱행..</p>
    <p>오래 쓰긴 글렀음..</p>
    `,
  },
  {
    name: `《동방럽》`,
    id: `dlwltn0722`,
    text: `
    <p>디자인도 별로고 넘 무거워요.. 실용적이지 못 하달까..? </p>
    `,
  },
  {
    name: `※레몬♬`,
    id: `thth2212`,
    text: `
    <p>넘 실망이에요ㅠㅠ 친구들이랑 놀러갈 때 메려고 맞춰서 시켰더니 넘 촌스러워서;;</p>
    <p>정도가 있지 이건 진짜 너무하네요...ㅠㅠ</p>
    `,
  },
  {
    name: `^정상To`,
    id: `jung88god`,
    text: `
    <p>조만간 망할 듯 ㅋ </p>
    `,
  },
  {
    name: `☞Adora☜`,
    id: `adora900`,
    text: `
    <p>독특한 스타일인데 전체적으로 어우러지지 않아요. 사진보다 실물로 보면 심하다고</p>
    <p>느껴지네요. 새로운 도전을 하고 싶었는데 실패했어요.</p>
    `,
  },
  {
    name: `했님반지현이`,
    id: `jihyern0zoo`,
    text: `
    <p>다른 곳에서 깔끔한 가방 구매하는 게 100번 나은 것 같아요. 이쁘단 느낌이 전혀 안</p>
    <p>듭니다... 후회하지 말고 다른 곳에서 구매하시길....</p>
    `,
  },
  {
    name: `이水`,
    id: `2su759486`,
    text: `
    <p>ㅋㅋㅋㅋㅋㅋㅋㅋㅋㅋㅋ이걸 가방이라고 만든 거임?ㅋㅋㅋㅋㅋㅋㅋㅋㅋㅋㅋㅋㅋ</p>
    `,
  },
  {
    name: `AdamLevine`,
    id: `m5aroon`,
    text: `
    <p>댓글 처음 달아보는데... 실물 진짜 쇼킹입니다.</p>
    `,
  },
  {
    name: `공길준기ㅛ`,
    id: `tjwns0427`,
    text: `
    <p>싫어하는 친구 선물로 추천 ㅋㅋㅋㅋㅋ</p>
    `,
  },
  {
    name: `ol별의or픔`,
    id: `good212digh`,
    text: `
    <p>여친한테 선물했더니 헤어지자고 하는데..요...?</p>
    `,
  },
  {
    name: `『㉬ㅏ란㉭ㅏ늘』`,
    id: `skyblue5mm`,
    text: `
    <p>이번에 취업해서 가방 구매했어요. 회사에서 이상한 사람으로 보는 것 같은데</p>
    <p>기분탓인가요?</p>
    `,
  },
];

export { bag_arr, color_obj, order_list, sample_image_list, review_list };
