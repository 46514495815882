import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './index.css';
import App from './App';
import ErrorPage from './components/ErrorPage/ErrorPage';
import PopUp from './components/PopUp/PopUp';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App cur={0} />,
    errorElement: <ErrorPage />
  },
  {
    path: "/order/:productId",
    element: <App cur={1} />,
    errorElement: <ErrorPage />
  },
  {
    path: "/payment/:paymentId",
    element: <App cur={2} />,
    errorElement: <ErrorPage />
  },
  {
    path: "/admin/orderlist/:orderListPage",
    element: <App cur={3} />,
    errorElement: <ErrorPage />
  },
  {
    path: "/admin/reviewlist/:reviewPage",
    element: <App cur={4} />,
    errorElement: <ErrorPage />
  },
  {
    path: "/alert",
    element: <PopUp />,
    errorElement: <ErrorPage />
  }
])

ReactDOM.createRoot(document.getElementById('root')).render(
  // <React.StrictMode>
    <RouterProvider router={router} />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals