import './App.css';
import './components/Main/Main.css';

import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Header from './components/Header/Header';
import MainRightBags from './components/Main/MainRightBags';
import MainOrder from './components/Main/MainOrder';
import MainLeft from './components/Main/MainLeft';
import MainRightPayment from './components/Main/MainRightPayment';
import MainRightOrderList from './components/Main/MainRIghtOrderList';
import MainRightReviewList from './components/Main/MainRIghtReviewList';


function App({ cur }) {

  // ** FUNCTION **
  const highLightRemover = (num) => {    
    const childNodes = document.getElementById('num_list').childNodes;

    const tempArr = [];

    for (let i = 0; i < childNodes.length; i++) {
      const t = childNodes[i];
      
      t.classList.remove("num_now");
      if(t.childNodes[1]) {
        tempArr.push(t);
      }
    }

    tempArr[(num%10) - 1].classList.add("num_now");
  }

  const changeSoldOut = () => {
    window.open('/alert','new', 'scrollbars=yes,resizable=no width=501 height=510, left=0,top=0');
    setSold(true);
  }


  // ** VARIABLE **
  const [ paymentInfo, setPaymentInfo ] = useState(null);
  const { productId, paymentId, orderListPage, reviewPage } = useParams();

  const [ isSold, setSold ] = useState(false);

  
  // ** MOUNT **
  useEffect(() => {
    if(paymentId && !paymentInfo) {
      const tempPay = paymentId.split("&");
      setPaymentInfo([tempPay[0], tempPay[1][1], tempPay[2].slice(1)]);
    }

    if(orderListPage) {
      highLightRemover(orderListPage);
    }
  }, [paymentId, paymentInfo, orderListPage])

  return (
    <div className="App">
      <div className='Container'>
        <Header changeSoldOut={changeSoldOut} />

        <div id='main'>
          <MainLeft cur={cur} />

          <div id='main_right'>
            {
              cur === 0 ?
              <div id='main_right_banner'>
                <div id='main_right_banner_1'>
                  <img src='/images/banner1.gif' alt='b1' />
                </div>
                <div id='main_right_banner_2'>
                  <img src='/images/banner2.gif' alt='b2' />
                </div>
              </div>
              :
              <></>
            }

            <div id='main_right_title' style={{ paddingLeft: "15px"}}>
              {
                cur === 0 ?
                "빈스의 신상 가방들"
                :
                cur === 1 ?
                "☆ 빈스 가방 ★"
                :
                cur === 2 ?
                "결제 ♬"
                :
                cur === 3 ?
                "주문 배송 관리"
                :
                cur === 4 ?
                "상품 후기"
                :
                "빈스 쇼핑몰"
              }
            </div>

            {
              cur === 0?
              <MainRightBags isSold={isSold} />
              :
              cur === 1?
              <MainOrder id={productId} />
              :
              cur === 2 ?
              <>
                {
                  paymentInfo ?
                  <MainRightPayment info={paymentInfo} />
                  :
                  <></>
                }
              </>
              :
              cur === 3?
              <MainRightOrderList orderListPage={orderListPage} />
              :
              cur === 4 ?
              <MainRightReviewList reviewPage={reviewPage} />
              :
              <div>Undefined Page</div>
            }

          </div>

        </div>
      </div>
    </div>
  );
}

export default App;