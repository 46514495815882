import { jaritsu, locationChange } from "../../common/modules/js/commonFunc";

import { bag_arr } from "../../content/content";

const MainRightBags = ({ isSold }) => {
  const splitIntoChunk = (arr, chunk) => {
    const result = [];

    for (let index = 0; index < arr.length; index += chunk) {
      let tempArray;
      tempArray = arr.slice(index, index + chunk);
      result.push(tempArray);
    }

    return result;
  };

  const arr = splitIntoChunk(Object.values(bag_arr), 3);
  const key = splitIntoChunk(Object.keys(bag_arr), 3);

  return (
    <div id="main_right_bags">
      {arr.map((e, i) => {
        return (
          <div className="bags_row" key={`bags_row_key_${i}`}>
            {e.map((e2, i2) => {
              return (
                <div
                  onClick={() => locationChange(`/order/${key[i][i2]}`)}
                  className={i2 === 1 ? "bags_col cent" : "bags_col"}
                  key={`bags_col_key_${i2}`}
                >
                  <div className="b_img">
                    <img src={`/images/${e2.img}.png`} alt="b_img" />
                  </div>
                  <div className="b_title">
                    {e2.title}
                    {isSold
                      ? [5, 1].map((e3, i3) => {
                          return (
                            <img
                              className="bags_status"
                              src={`/images/status/${e3}.png`}
                              alt="s"
                              key={`bags_row_status_key_${i}_${i2}_${i3}`}
                            />
                          );
                        })
                      : e2.status.map((e3, i3) => {
                          return (
                            <img
                              className="bags_status"
                              src={`/images/status/${e3}.png`}
                              alt="s"
                              key={`bags_row_status_key_${i}_${i2}_${i3}`}
                            />
                          );
                        })}
                  </div>
                  <div className="b_price">{jaritsu(e2.price) + "원"}</div>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default MainRightBags;
