import { useEffect, useRef } from "react";

import { review_list } from "../../content/content";

import { locationChange } from "../../common/modules/js/commonFunc";

function MainRightReviewList({ reviewPage = 1 }) {
  const OlRow = ({ obj, index }) => {
    const id = `OlRow_id_${index}`;

    const root = useRef(null);

    useEffect(() => {
      const textEl = document.getElementById(id);

      textEl.innerHTML = obj.text;

      const p_box = [];

      textEl.childNodes.forEach(e => {
        if (e.nodeName !== "#text") {
          p_box.push(e);
        }
      });

      root.current.style.height = `${20 + 15 * p_box.length}px`
    });

    return (
      <div className="ol_row" ref={root}>
        <div className="ol_row_7">
          {(page - reviewPage + 1) * 20 - index + page_n}
        </div>

        <div className="ol_row_8" id={id}>
          {/* innerHTML */}
        </div>

        <div className="ol_row_9">{obj.name}</div>

        <div className="ol_row_10">{obj.id}</div>

        <div className="ol_row_11 end">
          <div className="ol_able">삭제</div>
        </div>
      </div>
    );
  };

  const page = 112;
  const page_n = 2;

  const reviewListPageChange = (e) => {
    locationChange("/admin/reviewlist/" + e.target.textContent);
  }

  return (
    <div className="MainRightReviewList">
      <div className="order_list">
        <div className="ol_row ol_header">
          <div className="ol_row_7">번호</div>
          <div className="ol_row_8">댓글</div>
          <div className="ol_row_9">닉네임</div>
          <div className="ol_row_10">아이디</div>
          <div className="ol_row_11 end">관리</div>
        </div>

        {review_list.map((e, i) => {
          return <OlRow key={`ol_review_list_key_${i}`} obj={e} index={i} />;
        })}
      </div>

      <div id="num_list">
        <div style={{ fontSize: "12px", marginRight: "5px", width: "80px" }}>
          {`전체 : ${page}`}
        </div>
        {/* <div className='btn'>◁</div> */}
        <div className="num_btn num_now">
          [<span onClick={reviewListPageChange}>1</span>]
        </div>
        <div className="num_btn">
          [<span onClick={reviewListPageChange}>2</span>]
        </div>
        <div className="num_btn">
          [<span onClick={reviewListPageChange}>3</span>]
        </div>
        <div className="num_btn">
          [<span onClick={reviewListPageChange}>4</span>]
        </div>
        <div className="num_btn">
          [<span onClick={reviewListPageChange}>5</span>]
        </div>
        <div className="num_btn">
          [<span onClick={reviewListPageChange}>6</span>]
        </div>
        <div className="num_btn">
          [<span onClick={reviewListPageChange}>7</span>]
        </div>
        <div className="num_btn">
          [<span onClick={reviewListPageChange}>8</span>]
        </div>
        <div className="num_btn">
          [<span onClick={reviewListPageChange}>9</span>]
        </div>
        <div className="num_btn">
          [<span onClick={reviewListPageChange}>10</span>]
        </div>
        <div className="num_btn" style={{ width: "80px" }}>
          ▶
        </div>
      </div>

      <div style={{height: "100px"}}></div>
    </div>
  );
}

export default MainRightReviewList;
