import "./Header.css";

import { locationChange } from "../../common/modules/js/commonFunc";

function Header({ changeSoldOut }) {
  return (
    <div className="Header" id="header">
      <div id="header_top">
        <div id="header_top_logo">빈스 쇼핑몰</div>

        <div className="fBlank"></div>

        <div className="header_menu_b" onClick={() => locationChange("/")}>
          홈
        </div>
        <div
          className="header_menu_b"
          onClick={() => locationChange("/admin/orderlist/1")}
        >
          이용안내
        </div>
        <div className="header_menu_b">장바구니</div>
        <div className="header_menu_b">주문확인</div>
        <div className="header_menu_b">나의쇼핑</div>
        <div className="header_menu_b">공지사항</div>
      </div>

      <div id="header_mid" onClick={changeSoldOut}>
        <div id="header_menu"></div>
      </div>
    </div>
  );
}

export default Header;
