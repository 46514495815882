import { locationChange } from "../../common/modules/js/commonFunc";

function MainLeft({ cur }) {
  return (
    <div className="MainLeft" id="main_left">
      {cur === 3 || cur === 4 ? (
        <div id="login" className="menu">
          <div id="login_true">
            안녕하세요 <b>관리자</b>님
          </div>

          <div style={{ height: "10px" }}></div>

          <div className="login_btn_box">
            <div
              className="login_btn"
              style={{ margin: "5px 40px" }}
              onClick={() => locationChange("/")}
            >
              로그아웃
            </div>
          </div>

          <div style={{ height: "10px" }}></div>

          <div className="login_no">
            ▶ <span>주문 배송 관리</span>
          </div>
          <div className="login_no">
            ▶ <span>제품 등록</span>
          </div>
          <div className="login_no">
            ▶ <span>재고 관리</span>
          </div>
          <div className="login_no">
            ▶ <span>회원 관리</span>
          </div>
          <div className="login_no">
            ▶ <span>공지사항</span>
          </div>
        </div>
      ) : (
        <div id="login" className="menu">
          <div className="login_form">
            <div className="login_form_t">아이디</div>
            <div className="login_form_input">
              <input type="text" autoComplete="off" spellCheck="false" />
            </div>
          </div>
          <div className="login_form">
            <div className="login_form_t">비밀번호</div>
            <div className="login_form_input">
              <input type="password" />
            </div>
          </div>

          <div className="login_btn_box">
            <div className="login_btn">로그인</div>
            <div className="login_btn">회원가입</div>
          </div>

          <div className="login_no">
            ▶ <span>아이디 찾기</span>
          </div>
          <div className="login_no">
            ▶ <span>비밀번호 찾기</span>
          </div>
        </div>
      )}

      <div id="main_left_banner">
        <div className="mlb_box">
          <img src="/images/left_banner/left_b_4.png" alt="lb" />
        </div>
        <div className="mlb_box">
          <img src="/images/left_banner/left_b_1.png" alt="lb" />
        </div>
        <div className="mlb_box">
          <img src="/images/left_banner/left_b_2.png" alt="lb" />
        </div>
        <div className="mlb_box">
          <img src="/images/left_banner/left_b_3.png" alt="lb" />
        </div>
      </div>

      <div id="main_left_menu" className="menu">
        <div className="main_left_menu">
          <span className="mlm_arr">▶</span>
          <span>신상</span>
        </div>
        <div className="main_left_menu">
          <span className="mlm_arr">▶</span>
          <span>세일</span>
        </div>
        <div className="main_left_menu">
          <span className="mlm_arr">▶</span>
          <span>스페셜</span>
        </div>
        <div
          style={{
            borderTop: "dashed 1px var(--color-high2)",
            margin: "8px 0",
          }}
        ></div>
        <div className="main_left_menu">
          <span className="mlm_arr">▶</span>
          <span>공지사항</span>
        </div>
        <div className="main_left_menu">
          <span className="mlm_arr">▶</span>
          <span>구매후기</span>
        </div>
        <div className="main_left_menu">
          <span className="mlm_arr">▶</span>
          <span>다이어리</span>
        </div>
        <div className="main_left_menu">
          <span className="mlm_arr">▶</span>
          <span>문의하기</span>
        </div>
        <div className="main_left_menu">
          <span className="mlm_arr">▶</span>
          <span>이야기보따리</span>
        </div>
        <div className="main_left_menu">
          <span className="mlm_arr">▶</span>
          <span>특별한이벤트</span>
        </div>
      </div>

      <div id="main_left_menu_banner_end">
        <img src="/images/left_banner/left_banner_end.png" alt="lbe" />
      </div>
    </div>
  );
}

export default MainLeft;
